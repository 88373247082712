import {
    CREATE_CATEGORY_SUCCESS,
    CREATE_PRODUCT_SUCCESS,
    GET_CATEGORY_SUCCESS,
    GET_PRODUCT_SUCCESS,
    GET_CATEGORY_FAIL,
    GET_PRODUCT_FAIL,
    CREATE_CATEGORY_FAIL,
    CREATE_PRODUCT_FAIL,
    SET_MESSAGE, CREATE_SUBDOMAIN_SUCCESS, CREATE_SUBDOMAIN_FAIL,
} from "./types";

import ProductsService from "../services/products.service";

export const createProduct = (product, category,price,cargoref,priceenter, manufacturer) => (dispatch) => {
    return ProductsService.createProduct(product, category,price,cargoref,priceenter, manufacturer).then(
        (response) => {
            const parsed_response = Object.values(response)[0];
            if(parsed_response.startsWith('Дублювання')){
                dispatch({
                    type: CREATE_PRODUCT_FAIL,
                });
            }

            // if(parsed_response.startsWith('ok')){
            //     dispatch({
            //         type: CREATE_PRODUCT_SUCCESS,
            //     });
            // }

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: CREATE_PRODUCT_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

export const createCategory = (category) => (dispatch) => {
    return ProductsService.createCategory(category).then(
        (response) => {
            const parsed_response = Object.values(response)[0];
            if(parsed_response.startsWith('Дублирование')){
                dispatch({
                    type: CREATE_CATEGORY_FAIL,
                });
            }

            if(parsed_response.startsWith('ok')){
                dispatch({
                    type: CREATE_CATEGORY_SUCCESS,
                });
            }

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: CREATE_CATEGORY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};

export const getCategorys = () => (dispatch) => {
    return ProductsService.createCategory().then(
        (data) => {
            dispatch({
                type: GET_CATEGORY_SUCCESS,
            });

            return Promise.resolve();
        },
        (error) => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: GET_CATEGORY_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    );
};