import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import {TextField} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ProductService from "../services/products.service";
import ProductsService from "../services/products.service";
import {createCategory} from "../actions/products";
import {useDispatch} from "react-redux";
import ManyProducts from "../assets/ManyProducts.json"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import {useSnackbar} from "notistack";

export default function BoardProducts() {

    const [category, setCategory] = useState();
    const [cargodescription, setCargoDescription] = useState([]);
    const [cargoref, setCargoRef] = useState([]);
    const [productName, setProductName] = useState([]);
    const [price, setPrice] = useState();
    const [priceenter, setPriceEnter] = useState([]);
    const [typesArray, setTypesArray] = React.useState([]);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const loadCargoDescription = (event) => {
        event.preventDefault();
        const options = []
        ProductService.getCargoDescription()
            .then((response) => {
                Object.values(response.data.data).forEach(cat => {
                    let option = {
                        label: cat.Description,
                        id: cat.Ref
                    }
                    options.push(option)
                })
                setCargoDescription(options)
            })
            .catch(() => {
            });
    };

    const handleProduct = (event) => {
        event.preventDefault();
        setProductName(event.target.value);
        setCategory(event.target.value);
    };

    const handleChangePrice = (event) => {
        event.preventDefault();
        setPrice(event.target.value);
    };

    const handleChangeCargoDescription = (event, newValue) => {
        event.preventDefault();
        setCargoRef(Object.values(newValue)[1]);
    };

    const handleChangePriceEnter = (event) => {
        event.preventDefault();
        setPriceEnter(event.target.value);
    };

    const createProd = async (event) => {
        event.preventDefault();
        setLoading(true)
        await dispatch(createCategory(category))
        setTimeout(() => {
            const options = []
            ProductService.getCategories()
                .then((response) => {
                    Object.values(response.data)[0].forEach(cat => {
                        let option = {
                            label: cat.name,
                            id: cat.id
                        }
                        options.push(option)
                        if (cat.child.length !== 0) {
                            cat.child.forEach(child_cat => {
                                let option = {
                                    label: child_cat.name,
                                    id: child_cat.id
                                }
                                options.push(option)
                            })
                        }
                    })
                    let categoryId = options.filter(cat => cat.label === category)[0].id
                    typesArray.forEach(function (type, idx, array) {
                        setTimeout(() => {
                            ProductsService.createProductWithType(productName, type, categoryId, price, cargoref, priceenter).then(
                                (response) => {
                                    const parsed_response = Object.values(response)[0];
                                    if (parsed_response.startsWith('Дуб')) {
                                        setLoading(false)
                                        enqueueSnackbar('Товар вже існує!', {variant: "error"});
                                    }
                                    if (idx === array.length - 1) {
                                        ProductService.getProducts()
                                            .then((response) => {
                                                let sussesCount = 0
                                                Object.values(response.data).forEach(CreatedProduct => {
                                                        if (CreatedProduct.name.split(':')[0] === productName) {
                                                            sussesCount++
                                                            if (sussesCount === typesArray.length) {
                                                                enqueueSnackbar('Товари створено', {variant: "success"});
                                                                setLoading(false)
                                                            }
                                                        }
                                                    }
                                                )
                                            })
                                            .catch(() => {
                                            });
                                    }
                                },
                                (error) => {
                                    setLoading(false)
                                    enqueueSnackbar('Невідома помилка. Спробуйте ще раз.', {variant: "error"});
                                })
                        }, idx * 1100);
                    })
                })
                .catch(() => {
                });
        }, 6000);
    };

    return (
        <Box
            sx={{
                maxWidth: '60%',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: 4,
            }}
        >
            <Paper elevation={3} sx={{padding: 4}}>
                <Paper elevation={2} sx={{
                    marginBottom: 4,
                    color: 'white',
                    backgroundColor: '#1976d2',
                    padding: 1,
                    textShadow: '1px 0px 2px #000000'
                }}>
                    <center><h2>Масове створення товарів у CRM</h2></center>
                </Paper>

                <Grid container
                      spacing={2}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{marginBottom: 5}}
                >
                    <Grid item xs={5}>
                        <TextField sx={{padding: '10px 0 5px', minWidth: 300}}
                                   id="standard-basic"
                                   label="Назва товару"
                                   variant="standard"
                                   onChange={handleProduct}/>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField sx={{padding: '10px 0 5px', minWidth: 300}}
                                   id="standard-basic"
                                   label="Ціна"
                                   variant="standard"
                                   onChange={handleChangePrice}/>
                    </Grid>
                    <Grid item xs={5}>
                        <Autocomplete
                            loading
                            loadingText={'Завантаження...'}
                            disablePortal
                            id="combo-box-domains"
                            onOpen={loadCargoDescription}
                            options={cargodescription}
                            sx={{width: 300}}
                            onChange={handleChangeCargoDescription}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} sx={{padding: '5px 0 5px'}}
                                                                variant="standard"
                                                                label="Опис вантажу"/>}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField sx={{padding: '10px 0 5px', minWidth: 300}} id="standard-basic"
                                   label="Ціна закупки"
                                   variant="standard" onChange={handleChangePriceEnter}/>
                    </Grid>
                    <Grid item xs={5}>
                        <Autocomplete
                            disableClearable
                            disablePortal
                            id="combo-box-domains"
                            onChange={(event, newValue) => {
                                setTypesArray(ManyProducts.filter((item) => item.type === newValue)[0].variants)
                                console.log(ManyProducts.filter((item) => item.type === newValue)[0].variants)
                            }}
                            options={ManyProducts.map((option) => option.type)}
                            sx={{width: 300}}
                            renderInput={(params) => <TextField {...params} sx={{padding: '5px 0 5px'}}
                                                                variant="standard"
                                                                label="Тип продуктів"/>}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        {/*<Button variant="contained"*/}
                        {/*        onClick={createProd}*/}
                        {/*        sx={{padding: '5px 5 5px', marginBottom: 3, marginTop: 3}}*/}
                        {/*> Створити товари*/}
                        {/*</Button>*/}
                        <LoadingButton
                            onClick={createProd}
                            endIcon={<AddCircleIcon/>}
                            loading={loading}
                            loadingPosition="end"
                            variant="contained"
                            sx={{padding: '5px 5 5px', marginBottom: 3, marginTop: 3}}
                        >
                            Створити товари
                        </LoadingButton>
                    </Grid>
                </Grid>

            </Paper>
        </Box>
    )
}