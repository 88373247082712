import React, {useState, useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Routes, Route, Link} from "react-router-dom";

import "./bootstrap.min.css";
import "./App.css";

import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import BoardNewCampaing from "./components/Campaings/BoardNewCampaing";
import Dashboard from "./components/Dashboard/Dashboard";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardChecks from "./components/BoardChecks";
import {BrowserRouter} from "react-router-dom";

import {logout} from "./actions/auth";

import AuthVerify from "./common/AuthVerify";
import EventBus from "./common/EventBus";
import BoardCampaings from "./components/Campaings/BoardCampaings";
import BoardTestCampaing from "./components/Campaings/BoardTestCampaing";
import BoardProducts from "./components/BoardProducts";
import BoardMails from "./components/BoardMails";

const App = () => {
    const [showCampaingsMenu, setShowCampaingsMenu] = useState(false);
    const [showNewCampaingsMenu, setShowNewCampaingsMenu] = useState(false);
    const [showChecksMenu, setShowChecksMenu] = useState(false);
    const [showProductsMenu, setShowProductsMenu] = useState(false);
    const [showMailsMenu, setShowMailsMenu] = useState(false);

    const {user: currentUser} = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const logOut = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    useEffect(() => {
        if (currentUser) {
            setShowNewCampaingsMenu(currentUser.premissions.includes("CAMPAINGS"))
            setShowCampaingsMenu(currentUser.premissions.includes("NEW_CAMPAINGS"))
            setShowChecksMenu(currentUser.premissions.includes("CHECKS"));
            setShowProductsMenu(currentUser.premissions.includes("PRODUCTS"))
            setShowMailsMenu(currentUser.premissions.includes("MAILS"))
        } else {
            setShowNewCampaingsMenu(false);
            setShowCampaingsMenu(false);
            setShowChecksMenu(false);
            setShowProductsMenu(false)
            setShowMailsMenu(false)
        }

        EventBus.on("logout", () => {
            logOut();
        });

        return () => {
            EventBus.remove("logout");
        };
    }, [currentUser, logOut]);

    return (
        <div className="wrapper">
            <BrowserRouter>
                <div>
                    <nav className="navbar navbar-expand navbar-dark bg-dark">
                        <Link to={"/"} className="navbar-brand">
                            ADS Hero dev 1.9
                        </Link>
                        <div className="navbar-nav mr-auto">

                            {showCampaingsMenu && (
                                <li className="nav-item">
                                    <Link to={"/campaings"} className="nav-link">
                                        Компанії
                                    </Link>
                                </li>
                            )}

                            {showNewCampaingsMenu && (
                                <li className="nav-item">
                                    <Link to={"/campaings/new"} className="nav-link">
                                        Нова компанія
                                    </Link>
                                </li>
                            )}

                            {/*{showNewCampaingsMenu && (*/}
                            {/*    <li className="nav-item">*/}
                            {/*        <Link to={"/campaings/testoffer"} className="nav-link">*/}
                            {/*            Тестова компанія*/}
                            {/*        </Link>*/}
                            {/*    </li>*/}
                            {/*)}*/}

                            {showChecksMenu && (
                                <li className="nav-item">
                                    <Link to={"/checks"} className="nav-link">
                                        Чеки
                                    </Link>
                                </li>
                            )}
                            {showProductsMenu&& (
                                <li className="nav-item">
                                    <Link to={"/products"} className="nav-link">
                                        Товари
                                    </Link>
                                </li>
                            )}
                            {showMailsMenu&& (
                                <li className="nav-item">
                                    <Link to={"/mails"} className="nav-link">
                                        Пошти
                                    </Link>
                                </li>
                            )}
                        </div>

                        {currentUser ? (
                            <div className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <Link to={"/profile"} className="nav-link">
                                        {currentUser.username}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to={"/login"} className="nav-link" onClick={logOut}>
                                        Вихід
                                    </Link>
                                </li>
                            </div>
                        ) : (
                            <div className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <Link to={"/login"} className="nav-link">
                                        Вхід
                                    </Link>
                                </li>
                            </div>
                        )}
                    </nav>

                    <div>
                        <Routes>
                            <Route exact path="/" element={<Dashboard/>}/>
                            <Route exact path="/campaings/new" element={<BoardNewCampaing/>}/>
                            {/*<Route exact path="/campaings/testoffer" element={<BoardTestCampaing/>}/>*/}
                            <Route exact path="/campaings" element={<BoardCampaings/>}/>
                            <Route exact path="/login" element={<Login/>}/>
                            <Route exact path="/register" element={<Register/>}/>
                            <Route exact path="/profile" element={<Profile/>}/>
                            <Route path="/user" element={<BoardUser/>}/>
                            <Route path="/mod" element={<BoardModerator/>}/>
                            <Route path="/checks" element={<BoardChecks/>}/>
                            <Route path="/products" element={<BoardProducts/>}/>
                            <Route path="/mails" element={<BoardMails/>}/>
                        </Routes>
                    </div>
                    <AuthVerify logOut={logOut}/>
                </div>
            </BrowserRouter>
        </div>
    );
};

export default App;
